import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const StyledPropertyCard = styled.a<PropsWithTheme>`
	display: block;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	overflow: hidden;
	text-decoration: none;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[0]};
	`};
`;

export const StyledImageWrapper = styled.div<PropsWithTheme>`
	position: relative;
	aspect-ratio: 4 / 3;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[1000]};
	`};
`;

export const StyledTagWrapper = styled.div`
	display: flex;
	gap: 10px;
	top: 12px;
	left: 12px;
	position: absolute;
`;

export const StyledDescription = styled.div<PropsWithTheme>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: var(--spacing-xxxs);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.8) 100%);
	padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xxs);
	margin: 0;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;
