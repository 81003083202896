import React, { memo } from "react";

import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Spacer } from "@/components/layout/components";
import { StyledPropertyCard } from "@/components/property-card/styles";
import type { PropertyCardProps } from "@/components/property-card/types";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { Attribute, Attributes, AttributeSizeOptions } from "@/design-system/atoms/attribute/";
import { UnitType } from "@/design-system/atoms/unit";
import { usePropertyPrice } from "@/hooks/property";
import { useCountrySpecificArea, useCountrySpecificRooms } from "@/hooks/regional";
import { useTrackFeature } from "@/hooks/tag-manager";
import { TypographyVariant } from "@/theme";
import { PropertyOptions } from "@/types/property";

import { PropertyCardImage } from "./components";

export const PropertyCard = memo(
	({ disableLink, property, priorityLoad, shouldTrack }: PropertyCardProps) => {
		const { locale } = useRouter();
		const trackFeature = useTrackFeature();
		const { t } = useTranslation(["property", "common"]);
		const {
			sys: { id },
			displayAddress,
			featuredImage,
			options,
			salesStatus,
			hidePrice,
			exportedPropertyData: {
				data: { bedrooms, rooms, livingSpace, builtArea, propertySpace, price: rawPrice },
			},
		} = property;

		const isProject = options?.includes(PropertyOptions.Project);
		const priceLabel = usePropertyPrice(rawPrice, salesStatus, hidePrice, isProject);
		const unitPrefixValue = isProject ? t("common:from") : undefined;

		const { countrySpecificLivingSpace, countrySpecificLivingSpaceTitle } =
			useCountrySpecificArea(livingSpace, builtArea);
		const { countrySpecificRooms } = useCountrySpecificRooms(rooms, bedrooms);

		// If `disableLink` is true then we don't want next/link with anchor wrapper
		const WrappingComponent = disableLink
			? StyledPropertyCard.withComponent("div")
			: ({ children }) => (
					<Link
						passHref
						href={`/${dirs[Dirs.property].dir[locale] as string}/${id}/`}
						as={`/${dirs[Dirs.property].dir[locale] as string}/${id}/`}
						locale={locale}
						prefetch={false}
					>
						<StyledPropertyCard
							onClick={
								shouldTrack
									? () => {
											trackFeature("buyer_map_page", "card");
									  }
									: undefined
							}
						>
							{children}
						</StyledPropertyCard>
					</Link>
			  );

		return (
			<WrappingComponent>
				<PropertyCardImage
					image={featuredImage}
					options={options as PropertyOptions[]}
					salesStatus={salesStatus}
					priorityLoad={priorityLoad}
				>
					<Typography tight component="h4" variant={TypographyVariant.headlineSansXXS}>
						{displayAddress}
					</Typography>
				</PropertyCardImage>
				<Spacer spacing="xxs" />
				<Attributes size={AttributeSizeOptions.regular}>
					<Attribute icon="euro" title={t("property:price")} value={priceLabel} />
					{countrySpecificLivingSpace && (
						<Attribute
							icon="livingSpace"
							prefix={unitPrefixValue}
							title={countrySpecificLivingSpaceTitle}
							unit={UnitType.m}
							value={countrySpecificLivingSpace}
						/>
					)}
					{propertySpace && (
						<Attribute
							icon="landArea"
							prefix={unitPrefixValue}
							title={t("property:property-area")}
							unit={UnitType.m}
							value={propertySpace}
						/>
					)}
					{countrySpecificRooms && (
						<Attribute
							icon="rooms"
							prefix={unitPrefixValue}
							unit={UnitType.number}
							title={t("property:rooms")}
							value={countrySpecificRooms}
						/>
					)}
				</Attributes>
				<Spacer spacing="xxs" />
			</WrappingComponent>
		);
	}
);
