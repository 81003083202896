import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { Locale } from "@/contentful/content-model/types";

interface CountrySpecificAreaReturn {
	countrySpecificLivingSpace: number;
	countrySpecificLivingSpaceTitle: string;
}

interface CountrySpecificRoomsReturn {
	countrySpecificRooms: number | null;
}

/**
 * Custom hook to determine country-specific living space and title
 * takes also into account, which type of property it is (family, apartment, lot) and its specific spacing information
 * for spanish properties of type family, the built area is important, for german properties land area is important
 */
export const useCountrySpecificArea = (
	livingSpace: number | null,
	builtArea: number | null
): CountrySpecificAreaReturn => {
	const { locale } = useRouter();
	const { t } = useTranslation();

	// check for country and property type, since builtArea is only given for properties of type family
	const hasSpanishBuiltArea = locale === Locale.es && builtArea;
	const countrySpecificLivingSpace = hasSpanishBuiltArea ? builtArea : livingSpace;

	const countrySpecificLivingSpaceTitle = hasSpanishBuiltArea
		? t("property:built-area")
		: t("property:living-space");

	return {
		countrySpecificLivingSpace,
		countrySpecificLivingSpaceTitle,
	};
};

/**
 * Custom hook to determine country-specific rooms
 */
export const useCountrySpecificRooms = (
	rooms: number | null,
	bedrooms: number | null
): CountrySpecificRoomsReturn => {
	const { locale } = useRouter();

	return {
		countrySpecificRooms: locale === Locale.es ? bedrooms : rooms,
	};
};
