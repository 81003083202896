import React from "react";

import { useTranslation } from "next-i18next";

import Image from "next/image";

import { Badge, BadgeVariants } from "@/components/badge";
import {
	StyledDescription,
	StyledImageWrapper,
	StyledTagWrapper,
} from "@/components/property-card/styles";
import type { ImageProps } from "@/types/image";
import { PropertyOptions } from "@/types/property";

export const PropertyCardImage: React.FC<{
	image: ImageProps;
	options: PropertyOptions[];
	salesStatus?: string;
	priorityLoad?: boolean;
}> = ({ children, image, options, salesStatus, priorityLoad = false }) => {
	const { t } = useTranslation("common");
	const isSelected = options?.includes(PropertyOptions.Selected);
	const isHighlight = options?.includes(PropertyOptions.Highlight);
	const isInvestment = options?.includes(PropertyOptions.Investment);

	return (
		<StyledImageWrapper>
			<Image
				loader={({ src, width, quality }) => {
					return `${src}?w=${width}&q=${quality}&fm=webp`;
				}}
				src={image.url}
				sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
				alt={image.description}
				layout="fill"
				objectFit="cover"
				quality={75}
				priority={priorityLoad}
				placeholder={priorityLoad ? "empty" : "blur"}
				blurDataURL={`${image.url}?w=30&q=5&fm=jpg&fl=progressive`}
			/>
			<StyledTagWrapper>
				{salesStatus === "new" && (
					<Badge withShadow size="SM" variant={BadgeVariants.secondary}>
						{t("common:new")}
					</Badge>
				)}
				{salesStatus === "reserved" && (
					<Badge withShadow size="SM" variant={BadgeVariants.secondary}>
						{t("property:reserved")}
					</Badge>
				)}
				{salesStatus === "sold" && (
					<Badge withShadow size="SM" variant={BadgeVariants.tertiary}>
						{t("property:property-sold")}
					</Badge>
				)}
				{salesStatus === "comingSoon" && (
					<Badge withShadow size="SM" variant={BadgeVariants.secondary}>
						{t("property:coming-soon")}
					</Badge>
				)}
				{isSelected && (
					<Badge withShadow size="SM" variant={BadgeVariants.primary}>
						{t("property:selected")}
					</Badge>
				)}
				{isHighlight && (
					<Badge withShadow size="SM" variant={BadgeVariants.secondary}>
						{t("property:highlight")}
					</Badge>
				)}
				{isInvestment && (
					<Badge withShadow size="SM" variant={BadgeVariants.secondary}>
						{t("property:investment")}
					</Badge>
				)}
			</StyledTagWrapper>
			<StyledDescription>{children}</StyledDescription>
		</StyledImageWrapper>
	);
};
